@font-face {
    font-family: 'Signika';
    src: url('Signika-Regular.eot');
    src: url('Signika-Regular.eot?#iefix') format('embedded-opentype'),
        url('Signika-Regular.woff2') format('woff2'),
        url('Signika-Regular.woff') format('woff'),
        url('Signika-Regular.ttf') format('truetype'),
        url('Signika-Regular.svg#Signika-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Signika';
    src: url('Signika-Light.eot');
    src: url('Signika-Light.eot?#iefix') format('embedded-opentype'),
        url('Signika-Light.woff2') format('woff2'),
        url('Signika-Light.woff') format('woff'),
        url('Signika-Light.ttf') format('truetype'),
        url('Signika-Light.svg#Signika-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Signika';
    src: url('Signika-SemiBold.eot');
    src: url('Signika-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Signika-SemiBold.woff2') format('woff2'),
        url('Signika-SemiBold.woff') format('woff'),
        url('Signika-SemiBold.ttf') format('truetype'),
        url('Signika-SemiBold.svg#Signika-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Signika';
    src: url('Signika-Bold.eot');
    src: url('Signika-Bold.eot?#iefix') format('embedded-opentype'),
        url('Signika-Bold.woff2') format('woff2'),
        url('Signika-Bold.woff') format('woff'),
        url('Signika-Bold.ttf') format('truetype'),
        url('Signika-Bold.svg#Signika-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

