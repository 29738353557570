/** --------------------------------------
----- PROJECT- HazEL HTML CSS --------------
------ Style.css (30-12-2019) ----------- **/

@import url("assets/fonts/fonts_stylesheet.css");
@import url("assets/fonts/font-awesome.min.css");
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Signika', sans-serif;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

a:focus, a:hover {
  color: #055989;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Signika';
}

.m0 {
  margin: 0 !important;
}

.m5 {
  margin: 5px !important;
}

.m10 {
  margin: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.m20 {
  margin: 20px !important;
}

.m25 {
  margin: 25px !important;
}

.m30 {
  margin: 30px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.mtm5 {
  margin-top: -5px !important;
}

.p0 {
  padding: 0 !important;
}

.p5 {
  padding: 5px !important;
}

.p10 {
  padding: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.clr {
  clear: both;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  /*-webkit-appearance: none;*/
  /*margin: 0;*/
}

.navbar-default .navbar-toggle {
  border-color: #fff;
  margin-top: 18px;
}

  .navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
  }

  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: #0b71ab;
  }

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #0473b3;
}


.full-width-box {
  float: left;
  width: 100%;
  position: relative;
}

.loginPage {
  background: #055989;
}

.login-main {
  /*height: 537px;*/
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  margin: auto;
  background: #055989;
}

.login-logo {
  float: left;
  width: 66%;
  height: 100%;
  position: relative;
}

  .login-logo img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 352px;
  }

.login-form {
  float: left;
  width: 34%;
  height: 100%;
  background: #ffffff;
  padding: 0px 50px;
  text-align: center;
}

.login_form_wrapper {
  margin: 50% auto 0;
  display: inline-block;
  transform: translateY(-10%);
  max-width: 362px;
  text-align: left;
}

.form-title {
  float: left;
  width: 100%;
  font-weight: bold;
  color: #055989;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0.4px;
  line-height: 27px;
  margin-bottom: 29px;
  margin-top: 0;
  text-transform: uppercase;
}

.full-width {
  float: left;
  width: 100%;
}

.form-group {
  float: left;
  width: 100%;
  margin: 0 0 18px 0;
}

.form-control {
  float: left;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  color: #42494D;
  letter-spacing: 0.2px;
  border: 1px solid #CCCCCC;
  box-shadow: none;
  outline: none;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 6px 15px;
  transition: 0.3s linear;
}

  .form-control:focus {
    border-color: transparent;
    outline: 0;
    -webkit-box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.12);
  }

.searchInput {
  background-image: url(assets/images/search_icn.png);
  background-position: left 30px center;
  padding-left: 52px;
  background-repeat: no-repeat;
}

.form-control[type="password"] {
  font-weight: 600;
}

.control-label {
  float: left;
  width: 100%;
  color: #42494D;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin-bottom: 3px;
}

.forgotPass_btn {
  float: right;
  color: #055989;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 16px;
  margin-top: 10px;
  text-transform: uppercase;
  margin-bottom: 13px;
  text-decoration: none;
}

.btn_primary {
  background: #055989;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 600;
  padding: 13px 30px 12px;
  border-radius: 4px;
  display: inline-block;
  transition: 0.5s linear;
  border: 1px solid #055989;
  min-height: 50px;
  line-height: 21px;
  text-transform: uppercase;
  width: 100%;
  outline: none;
  cursor: pointer;
}

  .btn_primary a {
    color: #ffffff;
  }

    .btn_primary a:hover, .btn_primary a:focus {
      color: #055989;
    }

  .btn_primary:hover, .btn_primary:focus {
    color: #055989;
    outline: none;
    background: transparent;
    border: 1px solid #055989;
    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
  }

/*****************************************************
************ HEADER DESIGN  ***********************
****************************************************/
header {
  background-color: #055989;
  padding: 0 12px;
}

.navbar-default {
  background-color: transparent;
  border: none;
  min-height: 70px;
  border-radius: 0;
  margin-bottom: 0;
}

header .navbar-brand {
  float: left;
  height: auto;
  padding: 10px 0;
  font-size: 18px;
  line-height: 20px;
}

  header .navbar-brand img {
    height: 50px;
  }

header .navbar-right {
  margin: 20px 0;
}

  header .navbar-right.nav > li > a {
    position: relative;
    display: block;
    padding: 0;
    /*padding: 6.5px 10px;*/
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0.2px;
    margin: 0 0 0 0;
    border-radius: 0;
    line-height: normal;
    transition: 0.4s linear;
    font-weight: 300;
  }

  header .navbar-right.nav > li > div > a {
    position: relative;
    display: block;
    padding: 0;
    /*padding: 6.5px 10px;*/
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0.2px;
    margin: 0 0 0 0;
    border-radius: 0;
    line-height: normal;
    transition: 0.4s linear;
    font-weight: 300;
  }

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color: rgba(255, 255, 255, 0.7);
  background-color: transparent;
}

  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > li > div > a:focus, .navbar-default .navbar-nav > li > div > a:hover {
    color: rgba(255, 255, 255, 0.7);
    background-color: transparent;
  }

.navbar-nav > li {
  margin-left: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

  .navbar-nav > li.createNewAccountBtn_header {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

header .navbar-right.nav > li.createNewAccountBtn_header > a {
  color: #ffffff;
  background: #00A5E0;
  border-radius: 5px;
  padding: 12.5px 18px;
}

/** --------------------------------------------------
----------- SIDEBAR DESIGN  START-------------------**/
.sidebar {
  height: 100%;
  width: 230px;
  position: absolute;
  /*position: fixed;*/
  z-index: 4;
  top: 0;
  left: -240px;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 40px;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.16);
}

.sidebarMenu {
  float: left;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

  .sidebarMenu li {
    float: left;
    width: 100%;
  }

    .sidebarMenu li a {
      padding: 13px 20px 11px 30px;
      text-decoration: none;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: 0.2px;
      line-height: 16px;
      color: #42494D;
      display: block;
      transition: 0.3s;
    }

      .sidebarMenu li a:hover, .sidebarMenu li a.active:hover, .sidebarMenu li a.active {
        background: #055989;
        color: #ffffff;
      }

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 22px;
  margin-top: 13px;
}

  .sidebar .closebtn img {
    vertical-align: top;
  }

.openbtn {
  cursor: pointer;
  color: #055989;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  margin: 6px 20px 6px 0;
  float: left;
  font-size: 0;
}

  .openbtn img {
    vertical-align: top;
  }

  .openbtn:hover {
    opacity: 0.8;
  }

#main {
  transition: margin-left .5s;
}

/** --------------------------------------------------
----------- SIDEBAR DESIGN  END-------------------**/

/** FOOTER DESIGN  **/
.footer {
  float: left;
  width: 100%;
  background: #2B414D;
  padding: 0 30px;
}

.footer_bottom_content p {
  color: #AAAAAA;
  font-size: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  line-height: 15px;
  font-weight: 300;
  margin: 8px 0 7px;
}

/** -------------------------------------------------------------------
----------- RIGHT SIDE (DASHBOARD) CONTENT SECTION DESIGN START-------------------**/
section.main_content {
  float: left;
  width: 100%;
  min-height: calc(100vh - 70px );
  position: relative;
}

.section_titlebar {
  float: left;
  width: 100%;
  background: #F5F5F5;
  /*background: #FFFFFF;*/
  /*box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);*/
  padding: 7px 37px 7px 30px;
}

  .section_titlebar h2 {
    float: left;
    color: #42494D;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    line-height: 17px;
    margin: 5px 0 4px;
  }

.content_btm {
  float: left;
  width: 100%;
  padding: 30px;
}

.calender_icn {
  background-image: url(assets/images/icon-calendar.png);
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 16px;
}

/** SELECT2 Design **/
.select2.select2-container.select2-container--default {
  float: left;
  width: 100% !important;
  height: 40px;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  color: #42494D;
  letter-spacing: 0.2px;
  border: 1px solid #CCCCCC;
  box-shadow: none;
  outline: none;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 6px 15px;
  transition: 0.3s linear;
}

  .select2.select2-container.select2-container--default.select2-container--open {
    border-color: transparent;
    outline: 0;
    -webkit-box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.12);
  }

.select2.select2-container--default .select2-selection {
  background-color: transparent;
  border: none;
  border-radius: 0;
  height: auto;
  outline: none;
}

.select2.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #42494D;
  line-height: 27px;
  padding: 0;
  outline: none;
}

.select2.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 35px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 35px;
  background-image: url(assets/images/select_Drop.png);
  background-repeat: no-repeat;
  background-position: center center;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
  transform: rotateX(180deg);
  top: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  opacity: 0;
}

.select2-dropdown {
  -webkit-box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.14);
  border: 1px solid #e2e2e2;
}

  .select2-dropdown .select2-results__option {
    font-weight: 300;
  }

.filterAction_row .btn_primary {
  margin-top: 19px;
  min-height: 40px;
  font-size: 12px;
  letter-spacing: 0.2px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 30px 8px;
  width: 110px;
}

.btn_block.btn_primary {
  width: 100%;
}

.dashoard_chart {
  font-family: 'Signika';
  float: left;
  width: 100%;
  margin-top: 20px;
}

  .dashoard_chart .highcharts-point {
    display: none;
  }

  .dashoard_chart text.highcharts-title {
    color: #055989 !important;
    fill: #055989 !important;
    font-size: 18px;
    font-weight: bold !important;
    text-transform: uppercase;
    line-height: 22px;
    font-family: 'Signika'
  }

  .dashoard_chart g.highcharts-axis-labels text {
    color: #42494D !important;
    cursor: default;
    font-size: 14px !important;
    fill: #42494D !important;
    font-weight: bold !important;
    letter-spacing: 0.2px !important;
    font-family: 'Signika'
  }

  .dashoard_chart text.highcharts-axis-title tspan {
    color: #42494D !important;
    cursor: default;
    font-size: 18px !important;
    fill: #42494D !important;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: 0.2px !important;
    font-family: 'Signika'
  }

  .dashoard_chart .highcharts-legend-item.highcharts-line-series {
    display: none;
  }

.alertsTable .row .col-sm-12 {
  padding: 0;
}

.alertsTable .row {
  margin: 0;
}

.box {
  float: left;
  width: 100%;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 1px 4px hsla(0, 0%, 0%, 0.16);
  overflow: hidden;
}

.box_header {
  float: left;
  width: 100%;
  padding: 13px 19px 12px 30px;
}

  .box_header h3 {
    float: left;
    color: #42494D;
    margin: 3px 0 2px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
  }

.btn_primary.btn_sm {
  width: auto;
  float: right;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 13px;
  min-height: 25px;
  padding: 6px 10px;
}

.box_body {
  float: left;
  width: 100%;
}

/** TableDesign CSS **/
.tableDesign {
  float: left;
  width: 100%;
}

  .tableDesign .table > thead > tr > th {
    border-bottom: none;
  }

  .tableDesign .table > tbody > tr > td, .tableDesign .table > tbody > tr > th, .tableDesign .table > tfoot > tr > td, .tableDesign .table > tfoot > tr > th, .tableDesign .table > thead > tr > td, .tableDesign .table > thead > tr > th {
    border-top: 1px solid #F0F0F0;
    vertical-align: middle;
  }

  .tableDesign thead tr {
    background: #F5F5F5;
  }

    .tableDesign thead tr th {
      color: #42494D;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.2px;
      line-height: 15px;
      text-transform: uppercase;
      padding-right: 30px;
      padding-top: 12px;
      padding-bottom: 13px;
      padding-left: 30px;
    }

  .tableDesign tbody tr td {
    color: #42494D;
    font-weight: 300;
    font-size: 11px;
    letter-spacing: 0.2px;
    line-height: 13px;
    padding-right: 30px;
    padding-top: 22px;
    padding-bottom: 21px;
    padding-left: 30px;
  }

  .tableDesign table.dataTable {
    margin: 0 !important;
  }

  .tableDesign .dataTables_wrapper .row:last-child {
    background: #F5F5F5;
  }

.dataTables_paginate {
  float: right;
}

.tableDesign .dataTables_paginate ul.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: 0;
  background: transparent;
  height: auto;
  padding: 10px 20px;
  float: right;
}

.pagination > li {
  float: left;
  margin: 0 3.5px;
}

  .pagination > li > a, .pagination > li > span {
    font-size: 12px;
    color: #42494D;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 15px;
    text-transform: uppercase;
    min-width: 18px;
    height: 18px;
    background: transparent;
    border: none;
    display: inline-block;
    padding: 2px 2px;
    text-align: center;
    outline: none;
  }

.pagination .paginate_button.previous a {
  background-image: url(assets/images/previous_pagination.png);
  padding: 0;
  color: transparent !important;
  width: 18px;
  height: 18px;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center center;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 3;
  color: #ffffff;
  cursor: default;
  background-color: #055989;
  font-weight: bold
}

.pagination .paginate_button.next a {
  background-image: url(assets/images/next_pagination.png);
  padding: 0;
  color: transparent !important;
  width: 18px;
  height: 18px;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center center;
}

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
  color: #42494D;
  cursor: not-allowed;
  background-color: transparent;
  border-color: transparent;
}
/*.dataTables_wrapper [class^='col']{
    min-height: 0;
}*/

.mediaFeeds_row {
  float: left;
  width: 100%;
}

.mediaFeeds_row_ttl {
  float: left;
  width: 100%;
  background: #F5F5F5;
  color: #42494D;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 15px;
  padding: 12px 31px 13px;
  text-transform: uppercase;
}

.mediaFeeds_row_dtl {
  float: left;
  width: 100%;
  padding: 20px 30px;
}

.mediaFeeds_name {
}

  .mediaFeeds_name p {
    color: #AAAAAA;
    font-weight: 300;
    font-size: 10px;
    letter-spacing: 0.2px;
    line-height: 12px;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }

  .mediaFeeds_name h3 {
    color: #42494D;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 17px;
    display: block;
    /*float: left;
    width: 100%;*/
    margin-bottom: 10px;
    margin-top: 2px;
  }

.discp_feeds {
  float: left;
  width: 100%;
  color: #42494D;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  margin-bottom: 2px;
}

  .discp_feeds .seeMore_btn {
    color: #055989;
    text-decoration: underline;
    font-weight: normal;
    font-size: 9px;
    letter-spacing: 0.2px;
    line-height: 11px;
  }

.mediaFeeds_time {
  float: left;
  width: 100%;
  text-align: right;
  color: #42494D;
  font-weight: normal;
  font-size: 9px;
  letter-spacing: 0.2px;
  line-height: 11px;
}

.mediaFeedsScroll {
  overflow-y: auto;
}


/** -------------------------------------------------------------------
----------- Analysis PAGE DESIGN START-------------------**/
.tab_content_wrapper {
  float: left;
  width: 100%;
  margin-top: 30px;
}

.highcharts-container {
  width: 100% !important;
}

  .highcharts-container svg {
    width: 100% !important;
  }

.navTabs_Sec {
  float: left;
  width: 100%;
  text-align: center;
}

.tabbingDesign .nav-tabs {
  border: none;
  max-width: 843px;
  display: inline-block;
  margin: 0 auto;
  width: 100%;
}

  .tabbingDesign .nav-tabs > li > a {
    color: #42494D;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    line-height: 22px;
    padding: 20px 28px;
    /*padding: 20px 48px;*/
    position: relative;
    float: left;
    width: 100%;
    margin: 0;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .tabbingDesign .nav-tabs > li:first-child a {
    border-left: 1px solid #CCCCCC;
    border-radius: 4px 0 0 4px;
  }

  .tabbingDesign .nav-tabs > li:last-child a {
    border-right: 1px solid #CCCCCC;
    border-radius: 0 4px 4px 0;
  }

  .tabbingDesign .nav-tabs > li.active > a, .tabbingDesign .nav-tabs > li.active > a:focus, .tabbingDesign .nav-tabs > li.active > a:hover {
    color: #fff;
    cursor: default;
    font-weight: 600;
    background-color: #055989;
    border: 1px solid #055989;
  }

  .tabbingDesign .nav-tabs > li:nth-child(2) a:before {
    position: absolute;
    content: "";
    height: 15px;
    background: #CCCCCC;
    width: 1px;
    top: 0;
    bottom: 0;
    left: -1px;
    margin: auto;
  }

  .tabbingDesign .nav-tabs > li:nth-child(2) a:after {
    position: absolute;
    content: "";
    height: 15px;
    background: #CCCCCC;
    width: 1px;
    top: 0;
    bottom: 0;
    right: -1px;
    margin: auto;
  }

.largetableDesign.tableDesign thead tr {
  background: transparent;
}

  .largetableDesign.tableDesign thead tr th {
    background: #ECECEC;
  }

    .largetableDesign.tableDesign thead tr th:first-child {
      /*border-radius: 4px 0 0 4px;*/
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .largetableDesign.tableDesign thead tr th:last-child {
      /*border-radius: 0 4px 4px 0;*/
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

  .largetableDesign.tableDesign thead tr th {
    color: #42494D;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-transform: uppercase;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
  }

.largetableDesign.tableDesign tbody tr:first-child td {
  border-top: none;
}

.largetableDesign.tableDesign tbody tr td {
  color: #42494D;
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 16px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
}


/** -------------------------------------------------------------------
----------- MANAGE CHEMICALS PAGE DESIGN START-------------------**/
.manageChemicalsSec .tableDesign {
  margin-top: 12px;
}

.btnView.btn_primary {
  font-size: 12px;
  text-transform: capitalize;
  padding: 6px 8px;
  min-height: 30px;
  width: 73px;
  line-height: normal;
}

.width180.btn_primary {
  width: 150px;
  float: right;
}

.action_btn {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: inline-block;
  cursor: pointer;
}

  .action_btn:last-child {
    margin-right: 0;
  }

.eyeIcn {
  background-image: url(assets/images/eyeIcn.png);
}

.editIcn {
  background-image: url(assets/images/editIcn.png);
}

.branchIcn {
  background-image: url(assets/images/brnach-icon.png);
}

.addUserIcn {
  background-image: url(assets/images/add-user-new.png);
}

.deleteIcn {
  background-image: url(assets/images/deleteIcn.png);
}

.feedbackicn {
  background-image: url(assets/images/feedbackicn.png);
}

.saveIcon {
  background-image: url(assets/images/saveIcon.png);
}

/** -------------------------------------------------------------------
----------- VIEW SOCIAL MEDIA FEEDS PAGE DESIGN START-------------------**/
.viewSocialFeedsSec {
  float: left;
  width: 100%;
}

.btn_primary.back_btn {
  font-size: 12px;
  padding: 5px 8px 4px;
  min-height: 26px;
  width: 61px;
  line-height: normal;
  float: right;
}

.viewSocialFeeds_top_row {
  float: left;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

  .viewSocialFeeds_top_row .form-group p {
    color: #AAAAAA;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 15px;
    margin-bottom: 2px;
    float: left;
    width: 100%;
  }

  .viewSocialFeeds_top_row .form-group h3 {
    color: #42494D;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 22px;
    margin-bottom: 12px;
    margin-top: 0;
    float: left;
    width: 100%;
  }

.viewSocialFeedsSec .mediaFeeds_rowList {
  float: left;
  width: 100%;
}

.viewSocialFeedsSec .mediaFeeds_row {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.viewSocialFeedsSec .mediaFeeds_row_ttl {
  background: #ECECEC;
  border-radius: 4px 4px 0 0;
  padding: 10px 30px 10px;
}

  .viewSocialFeedsSec .mediaFeeds_row_ttl h3 {
    color: #42494D;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 20px;
    float: left;
    margin: 0;
  }

.viewSocialFeedsSec .mediaFeeds_time {
  float: right;
  color: #42494D;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2px;
  font-weight: normal;
  width: auto;
  margin: 3px 0 2px;
}

.viewSocialFeedsSec .mediaFeeds_row_dtl {
  float: left;
  width: 100%;
  padding: 30px 30px 30px;
  border: 1px solid #ECECEC;
  border-radius: 0 0 4px 4px;
}

  .viewSocialFeedsSec .mediaFeeds_row_dtl p {
    float: left;
    width: 100%;
    color: #42494D;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    margin-bottom: 5px;
  }


/** -------------------------------------------------------------------
----------- VIEW CHEMICAL DETAILS PAGE DESIGN START-------------------**/
.viewChemicalDetailsSec {
  float: left;
  width: 100%;
  padding-bottom: 140px;
}

.chemicalDetails_topSec {
  float: left;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 30px;
}

.detail_Sec_ttl {
  color: #42494D;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 22px;
  margin-bottom: 15px;
  padding-left: 30px;
  float: left;
  margin-top: 0;
}

.tableDesignBorder {
  border: 1px solid #ECECEC;
  border-radius: 4px;
}

  .tableDesignBorder table {
    margin-bottom: 0;
  }

.moreContentBtn_Sec {
  float: left;
  width: 100%;
  margin-top: 10px;
}

.moreContent_btn {
  color: #055989;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 16px;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.moreContent_Sec_1 {
  float: left;
  width: 100%;
  margin-top: 10px;
}

  .moreContent_Sec_1 .moreContent_Sec_ttl {
    background: #ECECEC;
    border-radius: 4px 4px 0 0;
    padding: 12px 30px 12px;
    float: left;
    width: 100%;
  }

    .moreContent_Sec_1 .moreContent_Sec_ttl h3 {
      color: #42494D;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.2px;
      line-height: 16px;
      float: left;
      margin: 0;
    }

  .moreContent_Sec_1 .close_moreContent {
    float: right;
    margin: 2px 0;
    cursor: pointer;
  }

    .moreContent_Sec_1 .close_moreContent img {
      width: 12px;
      vertical-align: top;
      float: right;
    }

  .moreContent_Sec_1 .moreContent_Sec_dtl {
    float: left;
    width: 100%;
    padding: 20px;
    border: 1px solid #ECECEC;
    border-radius: 0 0 4px 4px;
  }

    .moreContent_Sec_1 .moreContent_Sec_dtl p {
      float: left;
      width: 100%;
      color: #42494D;
      font-size: 13px;
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 16px;
      margin-bottom: 5px;
    }

.chemicalDetails_second_Sec .tableDesign .table > tbody > tr > td {
  border-top: none;
  border-bottom: 1px solid #CCCCCC;
  padding-right: 20px;
}

.chemicalDetails_second_Sec .tableDesign .table > tbody > tr:hover {
  background: #055989;
  color: #ffffff;
  border-radius: 4px;
}

.chemicalDetails_second_Sec .tableDesign .table > tbody > tr:hover a {
  color: #ffffff;
}

.chemicalDetails_second_Sec .tableDesign .table > tbody > tr:hover td {
  color: #ffffff;
}

i.infoTooltip {
  font-style: normal;
}

/** popover DESIGN **/
.popover {
  max-width: 271px;
  border: 1px solid #055989;
  background: #FFFFFF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: 'Signika', sans-serif;
}

  .popover.bottom {
    margin-top: 27px;
  }

    .popover.bottom > .arrow {
      top: -11px;
      left: 10% !important;
      border-bottom-color: #055989;
      border-bottom-color: rgb(5, 89, 137);
    }

      .popover.bottom > .arrow:after {
        top: 1.5px;
      }

.popover-content {
  padding: 20px;
  float: left;
  width: 100%;
}

  .popover-content ul {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

    .popover-content ul li {
      color: #42494D;
      font-size: 11px;
      font-weight: 300;
      font-style: normal;
      line-height: 13px;
      margin-bottom: 7px;
      float: left;
      width: 100%;
    }

      .popover-content ul li:last-child {
        margin-bottom: 0;
      }

.chemicalDetails_second_btns {
  float: left;
  width: 100%;
  text-align: right;
  margin-bottom: 30px;
}

  .chemicalDetails_second_btns .btn {
    width: auto;
    display: inline-block;
    margin-left: 30px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 15px;
    min-height: 40px;
    padding: 12px 31px 11px;
  }

    .chemicalDetails_second_btns .btn:first-child {
      margin-left: 0;
    }

.btn_bordered {
  border: 1px solid #AAAAAA;
  color: #AAAAAA;
  transition: 0.5s linear;
}

  .btn_bordered:hover, .btn_bordered:focus {
    color: #055989;
    outline: none;
    background: transparent;
    border: 1px solid #055989;
    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
  }

.chemicalDetails_second_Sec {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}


/** -------------------------------------------------------------------
----------- ADD CHEMICAL PAGE DESIGN START-------------------**/
.chemicalForm_topSec .form-group {
  margin-bottom: 30px;
}

  .chemicalForm_topSec .form-group .control-label {
    font-size: 13px;
  }

.chemicalForm_topSec {
  float: left;
  width: 100%;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 30px;
}

.resizeNo {
  resize: none;
}

.formTable .form-control {
  height: 35px;
  min-width: 120px;
}

.formTable tr td:nth-child(3) {
  width: 110px;
}

.formTable.tableDesign tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  position: relative;
}

.form_btns_Sec {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

  .form_btns_Sec .btn_primary {
    max-width: 166px;
    margin: 15px;
    min-height: 40px;
    line-height: 20px;
    font-size: 15px;
    padding: 9px 25px 9px;
  }


/** -------------------------------------------------------------------
----------- SDM MANAGEMENT PAGE DESIGN START-------------------**/
.sdmManagementSec .tableDesign {
  margin-top: 10px;
  width: 100%;
}

.tableDesign thead tr.secondHeading th {
  background: #f5f5f5;
  color: #055989;
  padding-top: 7px;
  padding-bottom: 6.5px;
}

.sdmManagementSec .largetableDesign.tableDesign tbody tr td {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: none;
  border-bottom: 1px solid #cccccc;
}

.sdmManagementSec .editIcn {
  background-size: 17px;
}

.weightageInput {
  width: 145px;
}

/** -------------------------------------------------------------------
----------- CHEMICAL PARAMETERS PAGE DESIGN START-------------------**/
.chemicalParametersSec .tableDesign {
  margin-top: 12px;
}

.chemicalParametersSec .largetableDesign.tableDesign tbody tr td {
  border-top: none;
  border-bottom: 1px solid #cccccc;
}

/** -------------------------------------------------------------------
----------- CHEMICAL CATEGORIES PAGE DESIGN START-------------------**/
.newCategory_btn.btn_primary {
  width: 222px;
  float: right;
  padding: 10px 24px 8px;
}

  .newCategory_btn.btn_primary b {
    font-size: 22px;
    font-weight: normal;
    margin-right: 5px;
    display: inline-block;
    float: left;
    margin-top: -2px;
  }

.numUnderline {
  text-decoration: underline;
}

.addChemicalCategories_sec .form-group {
  margin: 0 0 25px 0;
}

/** toggleBtnDesign CSS **/
.toggleBtnDesign {
  display: inline-block;
  position: relative;
}

  .toggleBtnDesign label {
    margin: 0;
    float: left;
  }

  .toggleBtnDesign input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    margin: 0;
  }

    .toggleBtnDesign input[type="checkbox"].ios-switch + div {
      vertical-align: middle;
      width: 38px;
      height: 20px;
      border: none;
      border-radius: 999px;
      background-color: #E0E0E0;
      -webkit-transition-duration: .4s;
      margin: 0;
      cursor: pointer;
    }

      .toggleBtnDesign input[type="checkbox"].ios-switch + div > div {
        float: left;
        width: 18px;
        height: 18px;
        border-radius: inherit;
        background: #ffffff;
        -webkit-transition-timing-function: cubic-bezier(.54,1.85,.5,1);
        -webkit-transition-duration: 0.4s;
        -webkit-transition-property: transform, background-color, box-shadow;
        -moz-transition-timing-function: cubic-bezier(.54,1.85,.5,1);
        -moz-transition-duration: 0.4s;
        -moz-transition-property: transform, background-color;
        box-shadow: -2px 1px 2px 1px #00000030;
        pointer-events: none;
        margin-top: 0.5px;
        margin-left: 1px;
      }

    .toggleBtnDesign input[type="checkbox"].ios-switch:checked + div > div {
      -webkit-transform: translate3d(18px, 0, 0);
      -moz-transform: translate3d(18px, 0, 0);
      background-color: #ffffff;
      box-shadow: -2px 1px 2px 1px #00000030;
    }

    .toggleBtnDesign input[type="checkbox"].ios-switch:checked + div {
      background: #055989;
    }



/** -------------------------------------------------------------------
----------- ALERTS PAGE DESIGN START-------------------**/
.newButton_btn.btn_primary {
  width: auto;
  float: right;
  padding: 10px 24px 8px;
  min-height: auto;
}

  .newButton_btn.btn_primary b {
    font-size: 22px;
    font-weight: normal;
    margin-right: 5px;
    display: inline-block;
    float: left;
    margin-top: -2px;
  }


/** -------------------------------------------------------------------
----------- NEW REQUEST PAGE DESIGN START-------------------**/
.newRequestSec.tabbingDesign .nav-tabs {
  max-width: 562px;
}

.newRequestSec .nav-tabs > li {
  width: 50%;
}

.newRequestSec.tabbingDesign .nav-tabs > li:nth-child(2) a:before, .newRequestSec.tabbingDesign .nav-tabs > li:nth-child(2) a:after {
  display: none;
}

.newRequestSec.tabbingDesign .nav-tabs > li > a {
  padding: 20px 20px;
}

.action_btn i.fa {
  color: #cccccc;
  font-size: 20px;
}

  .action_btn i.fa:hover, .action_btn i.fa.active {
    color: #055989;
  }



/** -------------------------------------------------------------------
----------- Add NEW COMPANY PAGE DESIGN START-------------------**/
.company_formttl_sec {
  float: left;
  width: 100%;
  background: #ececec;
  color: #42494D;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 22px;
  margin-bottom: 30px;
  padding: 10px 20px;
  border-radius: 4px;
}

.view_CompanySec .control-label {
  color: #aaaaaa;
}

.control_value {
  color: #42494D;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 22px;
  float: left;
  width: 100%;
  margin: 7px 0 12px;
}

/** -------------------------------------------------------------------
----------- View COMPANY PAGE DESIGN START-------------------**/
.btn_primary.btn_auto {
  padding: 9px 26px 6px;
  width: auto;
  float: right;
  min-height: 40px;
  font-size: 13px;
}

.company_branch_Sec {
  float: left;
  width: 100%;
  padding-top: 53px;
  border-top: 1px solid #cccccc;
  margin-top: 30px;
}

.invalid-username-pasword {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}


/* --------------Developer Style Changes Start -------------------- */
.show {
  /*display: block !important*/
}

.invalid-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.fade.show {
  opacity: 1;
}
/*.modal{display:block;}*/
.modal .modal-dialog {
  transition: 0.5s linear;
}

.fade {
  transition: .3s linear;
}

.modal-backdrop {
  background-color: #000000ad;
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0,0);
  transform: translate(0,0);
}
/* --------------Developer Style Changes End -------------------- */
angular2-multiselect.form-control {
  min-height: 40px;
  height: auto;
}

  angular2-multiselect.form-control .selected-list .c-btn {
    padding: 0;
    border: none;
    font-size: 12px;
    line-height: normal;
    min-height: auto;
    outline: none;
  }

  angular2-multiselect.form-control .selected-list .c-list .c-token {
    margin-bottom: 1px;
    margin-top: 1px;
    background: #055989;
    color: #ffffff;
  }

  angular2-multiselect.form-control .dropdown-list ul li {
    padding: 4px 10px;
  }

  angular2-multiselect.form-control .select-all {
    padding: 7px 10px;
    border-bottom: 1px solid #ccc;
    text-align: left;
  }

  angular2-multiselect.form-control .list-area {
    font-size: 13px;
  }

  angular2-multiselect.form-control .dropdown-list ul li:hover {
    background: whitesmoke;
  }

  angular2-multiselect.form-control .dropdown-list ul li.selected-item {
    background: #055989;
    color: #ffffff;
  }


/**------------------------------------------
MANAGE CHEMICALS
  **/

.largetableDesign.tableDesign tbody tr td.actionTd {
  width: 160px;
}

.pagination {
  width: 100%;
  background: #055989;
  padding: 10px 0;
  margin-bottom: 0;
}


  .pagination > li > a, .pagination > li > span {
    font-size: 14px;
    color: #ffffff;
    font-weight: 300;
    letter-spacing: .2px;
    line-height: 16px;
    text-transform: capitalize;
    min-width: 20px;
    height: 20px;
    background: 0 0;
    border: none;
    display: inline-block;
    padding: 2px 5px;
    text-align: center;
    outline: 0;
    border-radius: 2px;
  }

  .pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    z-index: 3;
    color: #055989;
    cursor: default;
    background-color: #ffffff;
    font-weight: 500;
  }

  .pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
    color: #ffffff;
    cursor: not-allowed;
    background-color: transparent;
    border-color: transparent;
  }

.selected-list .c-btn .ng-star-inserted {
  line-height: 24px;
}

.selected-list .c-list .c-token .c-label,
.selected-list .c-angle-down {
  line-height: normal !important;
}



/**------------------------------------------
Add MANAGE CHEMICALS  -- TABLE HEADER FIXED DESIGN------------------  **/
.table_fixed_header {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

  .table_fixed_header tbody {
    overflow: auto;
    height: 230px;
    display: block;
    width: 100%;
  }

  .table_fixed_header thead {
    width: 100%;
  }

    .table_fixed_header thead tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

  .table_fixed_header tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }


/** IMPORT EXPORT BTNS **/
.importExport_div {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

  .importExport_div .btn {
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 20px;
    min-height: 35px;
    width: auto;
    line-height: normal;
  }

.import_file_input_btn {
  position: relative;
}

  .import_file_input_btn .import_file_input {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
  }
/** viewAllChemicalsSec **/
.viewAllChemicalsSec .largetableDesign.tableDesign thead tr th {
  padding-left: 15px;
  padding-right: 15px;
}

.viewAllChemicalsSec .largetableDesign.tableDesign tbody tr td {
  padding-left: 15px;
  padding-right: 15px;
  white-space: normal;
  word-break: break-all;
}


angular2-multiselect.form-control .dropdown-list ul li {
  position: relative;
  padding: 16px 10px 16px 21px;
  border-bottom: solid 1px #d0d0d0;
}

angular2-multiselect#chemicalNameManage.form-control .dropdown-list ul li label {
  display: none;
}

angular2-multiselect.form-control .dropdown-list ul li p {
  margin: 0px;
  padding: 0 110px 0 0;
}

  angular2-multiselect.form-control .dropdown-list ul li p .btn {
    position: absolute;
    right: 16px;
    top: 10px;
    border: none;
    font-size: 12px;
    margin: 0 !important;
    border-radius: 7px;
    background: #5a99c9;
  }

  angular2-multiselect.form-control .dropdown-list ul li p a {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #1d1d1d;
    overflow: hidden;
  }

angular2-multiselect.form-control .dropdown-list ul li.selected-item a {
  color: #fff;
}

#weightingTab .mat-horizontal-content-container {
  padding: 0px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background: #5a99c9;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(39 108 167 / 54%);
}

.searchAnalysisInput {
  border-radius: 20px;
  margin: 30px 0 0 40px;
  max-width: 400px;
}

.Manage-accordion .tab_content_wrapper {
  padding-top: 0px !important;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #5a99c9;
}

#main {
  float: left;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 70px);
  margin-left: 0px !important;
}

.footer {
  position: relative;
  z-index: 1;
}


table.dataTable.no-footer {
  border-bottom-color: #ccc;
}
/*.nav-tabs > li > a {
  padding: 0 !important;
}*/
.joyride-button, .joyride-step__container {
  border-radius: 4px;
}
.joyride-button {
   background-color: #337ab7 !important;
  border-color: #2e6da4 !important;
}
  .joyride-button:hover {
    background-color: #fff !important;
    color: #337ab7 !important;
  }
.joyride-step__title {
  color: #337ab7 !important;
}
.joyride-step__container{
    height: auto !important;
}
.joyride-step__footer{
    margin-top: 15px;
}




/*Intro JS style*/
.introjs-button.introjs-button {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4 !important;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
}
  .introjs-button.introjs-button:hover {
    background-color: #286090;
    border-color: #204d74;
    color: #fff;
  }
.introjs-tooltiptext{
    font-size: 16px;
    color: #000;
}
.introjs-tooltip-header .introjs-skipbutton {
  color: #000;
  font-size: 32px;
  font-weight: 500;
  line-height: 20px;
  padding: 7px 0 7px 10px;
}
/*.test-class, body.helper-test .introjs-helperLayer {
   top: 446px !important;*/
 /* //top: 445px !important;*/
/*}


.test-class-backward, body.helper-test-backward .introjs-helperLayer {
  top: 360px !important;
}*/

.test-class, body.helper-test .introjs-helperLayer {
  top: calc(100vh - 491px) !important;
}

.test-class-backward, body.helper-test-backward .introjs-helperLayer {
  top: calc(100vh - 577px) !important;
}

.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 0%) 0px 0px 0px 0px, rgb(33 33 33 / 51%) 0px 0px 0px 5000px !important;
}

.input-btn-right {
  position: relative;
}

.input-btn-right .form-control {
  width: calc(100% - 45px);
}

.input-btn-right .btn.btn_primary {
  width: 35px;
  float: right;
  min-height: 35px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 30px;
}

.modalMultipleDomainParameter .modal-dialog {
  width: 800px;
}

.modalCompanyAlert .modal-dialog {
  width: 500px;
}

.header-checkbox {
 margin-left: 15px;
 vertical-align: middle;
}

.header-checkbox .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff !important;
}

.header-checkbox .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(105, 220, 22, 0.98);
}

.row-checkbox {
  margin-left: 125px;
}
.chemical-detail-label-para-count{
  font-size: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 25px;
  top: 36px;
  z-index: 999;
}
.chemical-detail-label-para-count span{
  display: inline-block;
  margin: -3px 0 0 10px;
}
.light {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #000;
}
.redCircle {
  background-color: #FF0000;
}
.greenCircle {
  background-color: #5cb85c;
}
.orangeCircle {
  background-color: #ffbb00;
}
.m-auto{
  margin: auto;   
}
#prioritisedChemicals_datatable th, #prioritisedChemicals_datatable td{
  max-width: 220px;
}

.indication-lightgreen {
  width: 20px; height: 20px; border-radius: 50%; border: 1px solid #000; background-color: #93B863;
}
.indication2-darkgreen {
  width: 20px; height: 20px; border-radius: 50%; border: 1px solid #000; background-color: #495E3C;
}
.indication-lightred {
  width: 20px; height: 20px; border-radius: 50%; border: 1px solid #000; background-color: #D74E09;
}
.indication-darkred {
  width: 20px; height: 20px; border-radius: 50%; border: 1px solid #000; background-color: #B80C09;
}

.light {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2px;
}
.modelPopupLabelECScore .modal-dialog{
  width: 100% !important;
}
.modelPopupLabelECScore .modal-content {
  max-width: 890px !important;
  width: 100%;
  margin: auto;
}
.modelPopupLabelECScore .tableDesign {
  max-height: 190px;
  overflow-y: auto;
}
.modelPopupLabelECScore .tableDesign table td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
#ecScoreChart{
  width: 850px; 
  margin: 0 auto;
}
.w-100 {
  width: 100%;
}

.modalAddChemical .modal-dialog {
  width: 1200px;
}
.modalAddChemical .modal-body {
  width: 1200px;
}
.modalAddChemical .control-label {
  font-size: 15px;
  padding-bottom: 5px;
  font-weight: 700;
  color: #1d1d1d;
}
.modalAddChemical #srchChemical {
  height: 34px;
}


/** -------------------------------------------------------------------
-----------  ANALYSIS DATA REPORT ALERT DETAILS PAGE DESIGN START-------------------**/
.analysisDataReportAlertDetails_viewChemicalDetailsSec {
  float: left;
  width: 100%;
  padding-bottom: 10px;
}

.analysisDataReportAlertDetails_chemicalDetails_topSec {
  float: left;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid #CCCCCC;
  /* margin-bottom: 30px; */
}

/* .dynamicParametersTable table.dataTable thead th {
  width: auto !important;
}

.dynamicParametersTable table.dataTable thead tr th:last-child,
.dynamicParametersTable table.dataTable tbody tr td:last-child {
  width: 100px !important;
}
.dynamicParametersTable table.dataTable tbody tr td:first-child {
  padding-right: 30px !important;
} */

.ui-tree {
  width: 100% !important;
  height: calc(100vh - 200px);
  overflow-y: auto;
} 

.ui-treenode-content {
  position: relative;
}
.ui-tree .ui-treenode-label  span,.ui-menuitem-text {
  font-family: 'Signika', sans-serif !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
  padding:0 !important;
}

body .ui-tree .ui-tree-container .ui-treenode  .ui-treenode-label {
  width:90%;
}

body .ui-tree .ui-tree-container .ui-treenode  .ui-treenode-label > span {
  display:flex;
  align-items: center;
  justify-content:space-between;
  width: 100%;
}

body .ui-tree .ui-tree-container .ui-treenode  .ui-treenode-label label {
  margin:0;
}

body .ui-tree .ui-tree-container .ui-treenode  .ui-treenode-children label {
  font-weight:500 !important;
}

body .ui-tree .ui-tree-container .ui-treenode label {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.ui-contextmenu {
  width: auto !important;
  min-width: 12.5em !important;
}

.cls-cursor {
  cursor: pointer;
}

/* Category popup for Data Page */
.modalPopupCategory .modal-dialog {
  width: 600px;
}
.modalPopupCategory .modal-body {
  width: 600px;
}
.modalPopupCategory .control-label {
  font-size: 15px;
  padding-bottom: 5px;
  font-weight: 700;
  color: #1d1d1d;
}
