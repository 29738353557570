

@media(max-width:1025px){
	/** Login Page responsive **/
	.login-logo{width: 55%;}
	.login-form{width: 45%;}
	.login_form_wrapper{transform: translateY(10%);}
}
@media(max-width:991px){

	/** Login Page responsive **/
	.login-main {
	    position: inherit;
	    float: left;
	    width: 100%;
	    text-align: center;
	    padding-bottom: 50px;
	}
	.login-logo {
	    width: 100%;
	    text-align: center;
	    padding: 50px 0;
	}
	.login-logo img { position: inherit; width: 200px;}
	.login-form {
		width: 100%;
	    max-width: 400px;
	    margin: 0 auto;
	    float: none;
	    display: inline-block;
	    height: auto;
	    padding: 50px 50px 30px;	
        border-radius: 10px;
	}
	.login_form_wrapper {
	    transform: none;
	    margin: 0;
	    max-width: 100%;
	}

	div#main { margin-left: 0 !important;}
	.tabbingDesign #analysisSecTab .navTabs_Sec .nav-tabs>li {
	    width: 33.33%;
	}
	.tabbingDesign .nav-tabs>li>a {
	    padding: 15px 10px;
	    font-size: 14px;
	}
	.viewSocialFeeds_top_row .form-group{
	    text-align: left;
	}
}
@media(max-width:767px){
	.navbar-nav>li {
	    float: left;
	    width: 100%;
	    margin: 10px 0;
	}
	.dashboardPage .box {
	    margin-bottom: 30px;
	}
	.alertsTable .row .col-sm-12 {
	    padding: 0 15px;
	}
	.alertsTable .row {
	    margin-right: -15px;
	    margin-left: -15px;
	}
	.chemicalDetails_second_btns .btn {
	    width: 29.33%;
	    margin: 0 2%;
	    padding: 12px 12px 11px;
	}
	.footer {
	    padding: 10px 0;
	}
	.footer_bottom_content {
	    text-align: center;
	}
	.footer_bottom_content p{
	    margin: 3px 0 4px;
	}
}
@media(max-width:568px){
	.tabbingDesign #analysisSecTab .navTabs_Sec .nav-tabs>li {
	    width: 100%;
	}
	.tabbingDesign #analysisSecTab .navTabs_Sec .nav-tabs>li a {
	    border: 1px solid #CCCCCC;
	}
	.chemicalDetails_second_btns .btn {
	    width: 100%;
	    margin: 0 0 10px;
	}

}